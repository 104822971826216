import { Box, Button } from "@mui/material";
import { differenceInHours } from "date-fns";
import { BookingType } from "../../global";
import { useCallback } from "react";
import {
  useActivityActionsList,
  useActivityTypesList,
} from "../hooks/useLists";
import { useCreateUserTimerActivityMutation } from "../../state/rtk-query/state/timer";
import { useSelector } from "react-redux";
import { timerSelectors } from "../../state";
import { useStartTimer, useStopTimer } from "../hooks";
interface Props {
  booking?: BookingType;
  miniCard?: boolean;
  orgId?: number;
}
export const JoinZoomMeetingButton = ({ booking, miniCard, orgId }: Props) => {
  const { getActionId } = useActivityActionsList();
  const { getActivityId } = useActivityTypesList();
  const [createTimerActivity] = useCreateUserTimerActivityMutation();

  const timerState = useSelector(timerSelectors.timerState);
  const { start_time, join_url, session_id } = booking ?? {};

  const sessionActivityId = getActivityId("sessions");
  const stopTimer = useStopTimer();
  const startTimer = useStartTimer(sessionActivityId);

  const isJoinable = start_time
    ? [0, 1].includes(differenceInHours(new Date(start_time), new Date()))
    : false;
  const sessionStart = getActionId("start_session");
  const handleJoinClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (!join_url || !orgId) {
        return;
      }
      if (sessionStart && session_id) {
        await createTimerActivity({
          body: {
            entity: "session",
            entity_id: session_id,
            action_id: sessionStart,
          },
        });
      }
      if (timerState === "active") {
        await stopTimer();
      }
      await startTimer({ org_id: orgId, manualStarted: true });
      window.open(join_url, '_blank');
    },
    [
      createTimerActivity,
      join_url,
      orgId,
      sessionStart,
      session_id,
      startTimer,
      stopTimer,
      timerState,
    ],
  );

  return (
    <Box sx={{ width: "100%", m: 2 }}>
      {start_time && join_url ? (
        <Button
          fullWidth
          onClick={handleJoinClick}
          variant={miniCard ? "contained" : "text"}
          size="small"
          disabled={!isJoinable}
          sx={{
            pointerEvents: !isJoinable ? "none" : "all",
            alignSelf: "flex-start",
            fontWeight: 600,
            fontSize: 14,
            zIndex: 9999,
          }}
        >
          Join session
        </Button>
      ) : null}
    </Box>
  );
};
